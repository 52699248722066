<template>
  <div class="detail">
    <Header></Header>
    <main class="layout content-inner">
      <div id="page" v-html="content"></div>
      <AsideContent></AsideContent>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/DetailHeader";
import AsideContent from "@/components/AsideContent";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "Page",
  data() {
    return {
      blogdata: [],
      content: "",
    };
  },
  components: {
    Header,
    Footer,
    AsideContent,
    ToTop,
  },
  watch: {
    $route() {
      this.$router.go(0);
    },
  },
  mounted() {
    this.blogdata = JSON.parse(localStorage.getItem("blogdetails"));
    this.content = this.blogdata.editorvalue;
  },
};
</script>

<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/1.jpeg");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
  max-width: 1200px;
}
.layout.hide-aside {
  max-width: 1000px;
}
#page {
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  width: 75%;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
  #page {
    width: 100%;
  }
}
</style>
