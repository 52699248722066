<template>
  <div class="Header" :style="headerBg">
    <div class="blankdiv" ref="blankdiv" @click.stop="showAsidebar"></div>
    <div class="nav fixed">
      <div class="logo">一缕阳光</div>
      <div id="menus">
        <div class="menus_items">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="#49b1f5"
          >
            <el-menu-item index="">
              <i class="el-icon-house"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="classify">
              <i class="el-icon-collection-tag"></i>
              <span slot="title">分类</span>
            </el-menu-item>
            <el-menu-item index="friendlink">
              <i class="el-icon-link"></i>
              <span slot="title">链接</span>
            </el-menu-item>
            <el-menu-item index="liuyan">
              <i class="el-icon-message"></i>
              <span slot="title">留言</span>
            </el-menu-item>
            <el-menu-item index="aboutme">
              <i class="el-icon-sugar"></i>
              <span slot="title">关于</span>
            </el-menu-item>
          </el-menu>
          <div id="search_button" @click.stop="showSearch">
            <a>
              <i class="fas fa-search fa-fw"></i>
              <span>搜索</span>
            </a>
          </div>
          <div id="toggle-menu" @click.stop="showAsidebar">
            <a>
              <i class="fas fa-bars fa-fw"></i>
              <span>菜单</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-menus" v-show="isShow">
      <div class="author-avatar">
        <img
          class="avatar-img"
          src="https://blog.likepoems.com//images/author.jpg"
        />
      </div>
      <div class="site-data">
        <div class="data-item is-center">
          <div class="data-item-link">
            <a href="/">
              <div class="headline">文章</div>
              <div class="length-num">{{ totalpages }}</div>
            </a>
          </div>
        </div>
        <div class="data-item is-center">
          <div class="data-item-link">
            <a href="/classify">
              <div class="headline">分类</div>
              <div class="length-num">{{ totalclassify }}</div>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div class="menus_items">
        <el-row class="tac">
          <el-col>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
              @select="handleSelect"
              active-text-color="#49b1f5"
            >
              <el-menu-item index="" class="menus_items">
                <i class="fa-fw fa fa-home"></i>
                <span slot="title">首页</span>
              </el-menu-item>
              <el-menu-item index="classify" class="menus_items">
                <i class="fa-fw fa fa-tags"></i>
                <span slot="title">分类</span>
              </el-menu-item>
              <el-menu-item index="friendlink" class="menus_items">
                <i class="fa-fw fa fa-link"></i>
                <span slot="title">链接</span>
              </el-menu-item>
              <el-menu-item index="liuyan" class="menus_items">
                <i class="fa-fw fa fa-coffee"></i>
                <span slot="title">留言</span>
              </el-menu-item>
              <el-menu-item index="aboutme" class="menus_items">
                <i class="fa-fw fa fa-heartbeat"></i>
                <span slot="title">关于</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
    </div>
    <div id="site-info">
      <h1 id="site-title">{{ blogdata.title }}</h1>
      <div id="site-subtitle">
        <span id="subtitle" ref="subtitle"></span>
        <div class="post-mata">
          <span class="post-meta-date">
            <i class="el-icon-date l1"></i>
            <span>发表于</span>
            <time>{{ blogdata.createtime }}</time>
          </span>
          <span class="post-meta-categories">
            <!-- <span class="l2">|</span> -->
            <i class="el-icon-collection-tag l2"></i>
            <a class="l2">{{ blogdata.category }}</a>
          </span>
          <span class="post-meta-view">
            <i class="el-icon-view l1"></i>
            <span>阅读量</span>
            <time title="阅读量">{{ blogdata.pageview }}</time>
          </span>
        </div>
      </div>
    </div>
    <div class="search-dialog" v-show="isSearch" ref="searchdialog">
      <div class="search-dialog__title">文章搜索</div>
      <div class="local-search-box">
        <input
          class="local-search-box--input"
          placeholder="搜索文章"
          type="text"
          v-model="searchPageVal"
        />
      </div>
      <hr />
      <div id="local-search-results">
        <div class="search-result-list" ref="searchnoneResult">
          <div
            class="local-search__hit-item"
            v-for="(item, index) in searchResult"
            :key="index"
            @click="go(item)"
          >
            <a class="search-result-title">{{ item.title }}</a>
            <p class="search-result">
              {{
                item.editorvalue
                  .replace(/&lt;\/?[^>]*&gt;/gi, "")
                  .replace(/[\r\n]/gi, "")
                  .replace(/\s+/gi, "")
                  .replace(/&nbsp;/gi, "")
              }}
            </p>
          </div>
        </div>
      </div>
      <span class="search-close-button" @click.stop="showSearch">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      index: "",
      blogdata: [],
      isShow: false,
      isSearch: false,
      totalpages: "",
      totalclassify: "",
      screenWidth: document.body.clientWidth,
      searchPageVal: "",
      searchResult: "",
      headerBg: {
        "background-image": "123",
      },
      baseUrl: "https://backblog.likepoems.com",
    };
  },
  methods: {
    handleSelect(key) {
      switch (key) {
        case "":
          this.$router.push("/");
          break;
        case "classify":
          this.$router.push("/classify");
          break;
        case "friendlink":
          this.$router.push("/friendlink");
          break;
        case "liuyan":
          this.$router.push("/liuyan");
          break;
        case "aboutme":
          this.$router.push("/aboutme");
          break;
      }
    },
    showAsidebar() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        this.$refs.blankdiv.style.display = "block";
        this.$refs.blankdiv.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
      } else {
        this.$refs.blankdiv.style.display = "none";
        this.$refs.blankdiv.style.backgroundColor = "none";
      }
    },
    getlatestblog() {
      this.$axios
        .get("/api/getlatestessay")
        .then((response) => {
          this.totalpages = response.data.totalpages;
          this.totalclassify = response.data.totalclassify;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    showSearch() {
      this.isSearch = !this.isSearch;
      if (this.isSearch) {
        this.$refs.searchdialog.style.display = "block";
        this.$refs.blankdiv.style.display = "block";
      } else {
        this.$refs.searchdialog.style.display = "none";
        this.$refs.blankdiv.style.display = "none";
      }
    },
    searchPage() {
      let self = this;
      self.$watch("searchPageVal", (val) => {
        self.$axios
          .get("/api/searchpages", { params: { searchKey: val } })
          .then((response) => {
            this.searchResult = response.data;
            if (this.searchResult.length == 0 && val) {
              this.$refs.searchnoneResult.innerText =
                "找不到您查询的内容：" + val;
              this.$refs.searchnoneResult.style.color = "#000";
            } else {
              this.$refs.searchnoneResult.innerText = "";
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    },
    go(data) {
      let pagedata = {
        pageid: data._id,
        pageview: data.pageview,
      };
      this.$axios
        .post("/api/pageview", pagedata)
        .then((response) => {
          console.log("success", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      let pagetitle = data.title.replace(/\s*/g, "");
      this.$router.push(`/pages/title=${pagetitle}`);
      localStorage.setItem("blogdetails", JSON.stringify(data));
    },
  },
  computed: {
    activeIndex() {
      return this.$route.path.replace("/", "");
    },
  },
  watch: {
    screenWidth(newVal) {
      if (newVal > 768) {
        this.isShow = false;
        this.$refs.blankdiv.style.display = "none";
        this.$refs.blankdiv.style.backgroundColor = "none";
      }
    },
    searchPageVal(val) {
      this.searchPageVal = val;
    },
  },
  mounted() {
    this.blogdata = JSON.parse(localStorage.getItem("blogdetails"));
    this.headerBg["background-image"] =
      "url(" + this.baseUrl + this.blogdata.cover + ")";
    this.getlatestblog();
    this.searchPage();

    const that = this;
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
    };
  },
};
</script>

<style scoped>
.Header {
  position: relative;
  width: 100%;
  background-color: #49b1f5;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
  color: #fff;
}
.nav {
  z-index: 90;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 36px;
  width: 100%;
  height: 60px;
  font-size: 1.3em;
  transition: all 0.5s;
}
.Header .logo {
  flex: 1;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 40px;
}

#menus {
  padding-right: 0 !important;
  overflow: hidden;
}
.el-menu,
.el-menu li:hover {
  background: none;
}
.el-menu--horizontal {
  display: inline-block;
}
.el-menu--horizontal > .el-menu-item {
  position: relative;
  display: inline-block;
  padding: 0 0.7rem;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
  color: #49b1f5;
}
.el-menu--horizontal > .el-menu-item {
  color: white;
  height: 40px;
  line-height: 40px;
}
.el-menu /deep/.el-menu-item i {
  color: #fff;
}
.el-menu.el-menu--horizontal {
  border: none;
  float: left;
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 100;
  color: #fff;
  box-shadow: 0 5px 6px -5px rgba(133, 133, 133, 0.6);
  transition: all 0.5s;
  background: rgb(133 133 133 / 60%);
}
#toggle-menu {
  display: none;
  padding: 0 0.7rem;
  padding-right: 0;
  vertical-align: top;
}
#toggle-menu a {
  font-size: 0.78em;
  cursor: pointer;
  color: #fff;
  height: 40px;
  line-height: 40px;
}
#toggle-menu a i {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 6px;
}
#toggle-menu .el-menu--horizontal > .el-menu-item {
  color: white;
  height: 40px;
  line-height: 40px;
}
#site-info {
  position: absolute;
  bottom: 8rem;
  padding: 0 8%;
  width: 100%;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
}
#site-info h1 {
  color: white;
  line-height: 1.2;
  position: relative;
  margin: 1rem 0 0.7rem;
  margin-bottom: 0.4rem;
  font-size: 2.5em;
  font-weight: normal;
}
#site-info #subtitle {
  font-size: 1.15em;
  color: #eee;
}
#search_button {
  float: left;
  height: 40px;
  line-height: 45px;
}
#search_button a {
  color: #fff;
  padding: 0 0.7rem;
  position: relative;
  text-shadow: 0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
  font-size: 0.78em;
}
#search_button i {
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .nav {
    width: 100%;
  }
  #menus ul {
    display: none !important;
  }
  .nav #toggle-menu {
    display: block;
    float: right;
  }
  #search_button {
    float: left;
    height: 40px;
    line-height: 45px;
    vertical-align: middle;
    display: table-cell;
  }
  .nav #toggle-menu {
    display: table-cell;
    float: right;
    vertical-align: middle;
    height: 40px;
    line-height: 45px;
  }
  #toggle-menu .site-page i {
    vertical-align: inherit;
    margin-right: 6px;
  }
  .search-dialog {
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 0;
  }
  .search-dialog .search-result-list {
    padding-bottom: 2rem;
    overflow-y: auto;
    max-height: 75vh !important;
  }
  #site-info h1 {
    font-size: 2em;
  }
  .post-mata .post-meta-date,
  .post-mata .post-meta-categories {
    display: block;
    text-align: center;
  }
}
.Header:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
}
.post-mata {
  color: #858585;
  font-size: 90%;
  font-family: "微软雅黑";
  color: #fff;
}
.post-mata .l1 {
  margin-left: 8px;
  margin-right: 8px;
}
.post-mata .l2 {
  margin-left: 8px;
  color: #fff;
}
</style>
